@font-face {
  font-family: 'Nexa';
  src: local('Nexa'), url('./fonts/Nexa-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: local('Nexa'), url('./fonts/Nexa-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: local('Nexa'), url('./fonts/Nexa-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: local('Nexa'), url('./fonts/Nexa-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

html, body {
  font-family: 'Nexa', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

sup {
  line-height: 0;
  font-size: 0.83em;
  vertical-align: super;
}

/* This is a fix for an Overlay overflow issue seen when using LastPass plugin on the personal details,
   when the user selects a date of more than 2 weeks ahead */
iframe#webpack-dev-server-client-overlay{display:none!important}